<template>

  <div class="po__details_page">
    <!-- Table Container Card -->
    <div class="without__bg">
      <b-card
        no-body
      >
        <b-row
          class="content-header"
        >
          <b-col
            class="content-header-left mb-2"
            cols="7"
            md="7"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Invoice {{ invoice.invoiceID }}
                </h1>
                <p
                  v-if="invoice.status == 'cancelled'"
                  class="log_info"
                >
                  Cancelled on {{ dateFormatWithTime(invoice.updatedAt) }} by {{ invoice.updatedBy ? invoice.updatedBy.name : '' }}
                </p>
                <p
                  v-else
                  class="log_info"
                >
                  Last edited by {{ invoice.updatedBy ? invoice.updatedBy.name : '' }} on {{ dateFormatWithTime(invoice.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col><!-- Content Left -->
        </b-row>
        <div class="pur_con_header">
          <b-row
            class="content-header"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="6"
            >
              <b-button
                variant="primary"
                class="mr-1 md-mb-1"
                :disabled="invoice.status == 'cancelled' || invoice.status == 'completed' || !canViewThisAction('update', 'VendorPayment')"
                @click="warningBeforeEdit(invoice._id)"
              >
                Edit
              </b-button>
              <b-button
                v-if="invoice.status == 'cancelled'"
                variant="flat-primary"
                class="mr-1 md-mb-1"
                disabled
                style="background: #FFFFFF; opacity: 1"
              >
                <feather-icon
                  icon="FileMinusIcon"
                  class="mr-50"
                  size="18"
                />
                <span class="align-middle">
                  Invoice Cancelled
                </span>
              </b-button>
              <b-button
                v-else
                variant="flat-primary"
                class="mr-1 md-mb-1"
                style="background: #D2DCEA;"
                :disabled="invoice.status == 'completed' || (!canViewThisAction('cancel', 'VendorPayment') && !(canViewThisAction('my-vp-cancel', 'MyVendorPayment') && user._id == (invoice.createdBy ? invoice.createdBy._id : '')))"
                @click="warningBeforeCancel(invoice._id, invoice.invoiceID)"
              >
                Cancel Invoice
              </b-button>
            </b-col>
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="6"
            >
              <div
                v-for="(signatory, signIndex) of invoice.signatories"
                :key="signIndex"
              >
                <b-button
                  v-if="invoice.status != 'cancelled' && user._id == (signatory.user ? signatory.user._id : '') && (signIndex == 0 ? invoice.signatories[signIndex].signed == false : invoice.signatories[signIndex - 1].signed == true) && signatory.signed == false"
                  variant="success"
                  :to="{ name: 'purchasing-vendor-payment-preview', params: { id: invoice._id }, query: { type: signIndex, user: signatory._id } }"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                    size="18"
                  />
                  <span class="align-middle">
                    Approve Invoice
                  </span>
                </b-button>
              </div>

              <b-button
                v-if="invoice.cpCheckStatus == 'checked' && (invoice.deptCheckStatus == 'approved' || invoice.deptCheckStatus == 'reviewed') && canViewThisAction('add-finance-remarks', 'VendorPayment')"
                variant="flat-primary"
                class="mr-1 md-mb-1"
                style="background: #D2DCEA;"
                :disabled="invoice.status == 'cancelled'"
                @click="openPaymentRemarksModal()"
              >
                <span class="align-middle">
                  Edit Payment Remarks
                </span>
              </b-button>

              <b-button
                v-if="invoice.cpCheckStatus == 'checked' && (invoice.deptCheckStatus == 'approved' || invoice.deptCheckStatus == 'reviewed') && canViewThisAction('update-payment-status', 'VendorPayment')"
                variant="success"
                class="md-mb-1"
                :disabled="invoice.status == 'cancelled'"
                @click="openPaymentStatusModal()"
              >
                <feather-icon
                  icon="ArrowRightIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">
                  Update Payment Status
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div class="pur_con_body quote__table">
          <b-row style="margin-right: 0;">
            <b-col
              md="10"
              style="padding-right: 0;"
            >
              <b-table-simple
                ref="teamsTable"
                class="position-relative has_padding quote__table"
                responsive
                caption-top
              >
                <b-thead head-variant="dark">
                  <b-tr class="group__tag">
                    <b-th>
                      INvoice Details / Vendor name
                    </b-th>
                    <b-th>
                      Invoice & Attachment
                    </b-th>
                    <b-th>
                      Uploaded by / Remarks
                    </b-th>
                    <b-th>
                      Budget Code
                    </b-th>
                    <b-th>
                      Payment Method
                    </b-th>
                    <b-th>
                      Payment Detail
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  class="white-body-background"
                >
                  <b-tr>
                    <b-td>
                      <span class="text-bold-black">{{ invoice.invoiceID }}</span>
                      <br>
                      {{ invoice.vendorName }}
                    </b-td>
                    <b-td>
                      <div v-if="invoice.attachments && invoice.attachments.length">
                        <b-row
                          v-for="(file, key) in invoice.attachments"
                          :key="key"
                        >
                          <!-- Content Left -->
                          <b-col
                            class="content-header-left mb-2"
                            cols="12"
                            md="12"
                          >
                            <div
                              class=""
                            >
                              <b-img
                                v-if="file.type.includes('image')"
                                left
                                height="20"
                                :src="require('@/assets/images/admin/doc_jpg.png')"
                                alt="Left image"
                              />
                              <b-img
                                v-if="file.type.includes('application')"
                                left
                                height="20"
                                :src="require('@/assets/images/admin/doc_file.png')"
                                alt="Left image"
                              />
                              <span class="ml-75">
                                <b-link
                                  :href="file ? file.data : ''"
                                  target="_blank"
                                >
                                  {{ file ? file.name : '' }}
                                </b-link>
                              </span>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </b-td>
                    <td>
                      <span class="">{{ invoice.createdBy ? invoice.createdBy.name : '' }}</span>
                      <br>
                      {{ dateFormatWithTime(invoice.createdAt) }}
                      <br>
                      {{ invoice.uploaderRemarks }}
                    </td>
                    <td>
                      {{ getExpenseID(invoice.budgetCode) }}
                    </td>
                    <td>
                      {{ invoice.paymentMethod || '-' }}
                    </td>
                    <td>
                      <span class="text-bold-black text-nowrap">$ {{ numberFormat(invoice.totalPayable) }}</span>
                      <br>
                      <small class="text-muted">Total Payable</small>
                      <br>
                      <span class="text-nowrap">$ {{ numberFormat(invoice.subTotal) }}</span>
                      <br>
                      <small class="text-muted">Sub Total</small>
                      <br>
                      <span class="text-nowrap">$ {{ numberFormat(invoice.gst) }}</span>
                      <br>
                      <small class="text-muted">GST</small>
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <hr
                class="dividerHR"
              >
              <div class="signatories_block p-3">
                <b-row
                  class="content-header"
                >

                  <!-- Content Left -->
                  <b-col
                    v-for="(signatory, signIndex) of invoice.signatories"
                    :key="signIndex"
                    cols="3"
                    md="3"
                    class="mt-md-0 mt-3"
                    order="2"
                    order-md="1"
                  >
                    <b-card-text class="mb-0">
                      <span class="font-weight-bold">{{ signIndex + 1 }}. {{ signatory.type }}</span>
                      <div
                        class="sign-box"
                        style="border: 1px solid #D2DCEA;"
                      >
                        <div v-if="signatory.user">
                          <b-avatar
                            v-if="signatory.user.image"
                            :src="signatory.user.image"
                          />
                          <b-avatar
                            v-else
                            :text="resolveAcronymName(signatory.user.name)"
                          />
                        </div>
                        <div v-if="signatory.signed == true">
                          <div v-if="signatory.user && signatory.user.signature">
                            <b-img
                              class="mb-1 mb-sm-0 user__avatar"
                              height="50"
                              :src="signatory.user.signature"
                            />
                          </div>
                          <div v-else-if="signatory.user && signatory.user.name">
                            <span>{{ signatory.user.name }}</span>
                          </div>
                        </div>
                        <div v-else>
                          <p>Pending Signature</p>
                        </div>
                      </div>
                      <p class="ml-75 semi-bold">
                        {{ signatory.user ? signatory.user.name : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                      </p>
                      <p class="ml-75">
                        {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                      </p>
                    </b-card-text>
                    <div class="doc__desc remarks">
                      <span v-if="signatory.remarks">
                        {{ signatory.remarks }}
                      </span>
                      <span v-else>
                        <button
                          v-if="invoice.status != 'cancelled' && user._id == (signatory.user ? signatory.user._id : '')"
                          @click="updateSignatoryRemarks(signIndex)"
                        >
                          Add remarks
                        </button>
                      </span>
                      <span
                        v-if="signatory.remarks"
                        class="edit__desc"
                      >
                        <b-button
                          v-if="invoice.status != 'cancelled' && user._id == (signatory.user ? signatory.user._id : '')"
                          variant="flat-primary"
                          @click="updateSignatoryRemarks(signIndex)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                            size="18"
                          />
                        </b-button>
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              md="2"
              style="background: #F5F8FB; padding-right: 0; padding-left: 0;"
            >
              <div style="background: #D2DCEA; padding: 11px 21px; font-weight: 600; text-transform: uppercase; color: #000000; line-height: 15px; font-size: 12px;">
                For finance use only
              </div>
              <div style="padding: 11px 21px;">
                <span class="text-bold-black d-block mb-50">
                  Payment Status
                </span>
                <div v-if="invoice.paymentStatus">
                  <b-badge
                    :variant="getBadgeVariant(invoice.paymentStatus)"
                    class="text-capitalize light-font"
                  >
                    {{ invoice.paymentStatus }}
                  </b-badge>
                </div>
                <div v-else-if="invoice.cpCheckStatus == 'checked' && (invoice.deptCheckStatus == 'approved' || invoice.deptCheckStatus == 'reviewed')">
                  <b-badge
                    variant="light-primary"
                    class="light-font"
                  >
                    To process
                  </b-badge>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
                <div v-if="invoice.status != 'cancelled'">
                  <br>
                  <span class="text-bold-black">
                    Processed by
                  </span>
                  <span
                    class="d-block mt-50"
                    style="font-weight: 500;"
                  >
                    {{ invoice.processedBy ? invoice.processedBy.name : '-' }}
                  </span>
                  <span style="font-size: 12px;">
                    Finance
                  </span>
                  <br>
                  <span style="font-size: 12px;">{{ invoice.processedDate ? dateFormatWithTime(invoice.processedDate) : '-' }}</span>
                  <span class="text-bold-black d-block mt-1">
                    Remarks
                  </span>
                  <span class="d-block my-50">{{ invoice.financeRemarks || '-' }}</span>
                  <div v-if="invoice.financeRemarks">
                    <span>By {{ invoice.financeRemarksBy ? invoice.financeRemarksBy.name : '-' }}</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>

    <b-modal
      id="modal-signatory-remarks"
      size="sm"
      title="Add Remarks"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveSignatoryRemarks"
      @cancel="closeRemarksModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="remarks"
            placeholder="Remarks"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!remarks || signatoryIndex === ''"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-finance-remarks"
      size="sm"
      title="Edit Payment Remarks"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      @ok="saveFinanceRemarks"
      @cancel="closeFinanceRemarksModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Payment Remarks"
            label-for="h-finance-remarks"
          >
            <v-select
              id="h-finance-remarks"
              v-model="financeRemarksStatus"
              label="title"
              placeholder="Choose a remark from list"
              :options="financeRemarksStatusOptions"
              :reduce="title => title.code"
              :clearable="true"
              @input="changeFinanceRemarks"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="financeRemarks"
            rows="5"
            placeholder="Add remarks here, or choose from the list above."
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!financeRemarks && !financeRemarksStatus"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-finance-payment-status"
      size="sm"
      title=""
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal payment-status"
      @ok="savePaymentStatus"
      @cancel="closePaymentStatusModal"
    >
      <template #modal-header>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          class="primary-button-with-background"
        >
          <feather-icon
            icon="GridIcon"
            class=""
            size="20"
          />
        </b-button>
        <h3 class="align-middle">
          <span class="step-class">Payment Required</span>
          Update Payment Status?
        </h3>
        <!-- <div class="stock-items-modal-header">
            <feather-icon
              icon="GridIcon"
              size="20"
            />
            <div class="stock-in-item-modal-heading-block">
              <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
                Process request and stock out selected item(s)?
              </h4>
              <p style="color: #333333;">
                Please review the inventory item(s) below. Items will be stocked out from each store.
              </p>
            </div>
          </div> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="closePaymentStatusModal"
        />
      </template>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Payment Status"
            label-for="h-finance-status"
          >
            <v-select
              id="h-finance-status"
              v-model="paymentStatus"
              label="title"
              placeholder="Select from list"
              :options="paymentStatusOptions"
              :reduce="title => title.code"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Payment Method"
            label-for="h-finance-method"
          >
            <v-select
              id="h-finance-method"
              v-model="paymentMethod"
              label="title"
              placeholder="Select from list"
              :options="paymentMethodOptions"
              :reduce="title => title.code"
              :clearable="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Remarks"
            label-for="h-finance-remarks"
          >
            <v-select
              id="h-finance-remarks"
              v-model="financeRemarksStatus"
              label="title"
              placeholder="Choose a remark from list"
              :options="financeRemarksStatusOptions"
              :reduce="title => title.code"
              :clearable="true"
              @input="changeFinanceRemarks"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="financeRemarks"
            rows="5"
            placeholder="Add remarks here, or choose from the list above."
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="success-without-button"
          :disabled="!paymentStatus"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Update</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
  BLink, VBTooltip, BBadge, BCardText, BAvatar, BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BLink,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    BCardText,
    BAvatar,
    BFormTextarea,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      purchaseRequest: {},
      invoice: {},
      expenseItems: [],
      remarks: '',
      signatoryIndex: '',
      invoiceIndex: '',
      financeRemarksStatus: '',
      financeRemarks: '',
      paymentMethod: '',
      paymentStatus: '',
      required,
      financeRemarksStatusOptions: [
        { title: 'Under Processing', code: 'Under Processing' },
        { title: 'Cheque Ready/ Online Payment Authorisation Completed', code: 'Cheque Ready/ Online Payment Authorisation Completed' },
        { title: 'Incomplete Documents', code: 'Incomplete Documents' },
        { title: 'Insufficient Documents', code: 'Insufficient Documents' },
        { title: 'Wrong Billing/ Company Name', code: 'Wrong Billing/ Company Name' },
        { title: 'Wrong Entity Raised by Requestor', code: 'Wrong Entity Raised by Requestor' },
        { title: 'Irregular Signatory', code: 'Irregular Signatory' },
        { title: 'Wrong Amount/ GST', code: 'Wrong Amount/ GST' },
        { title: 'Tax Invoice Requested', code: 'Tax Invoice Requested' },
      ],
      paymentStatusOptions: [
        { title: 'Processing', code: 'Processing' },
        { title: 'Paid', code: 'Paid' },
        { title: 'Rejected', code: 'Rejected' },
      ],
      paymentMethodOptions: [
        { title: 'Cheque', code: 'Cheque' },
        { title: 'Bank Transfer (Direct Debit)', code: 'Bank Transfer (Direct Debit)' },
        { title: 'GIRO (Monthly Auto Deduction)', code: 'GIRO (Monthly Auto Deduction)' },
        { title: 'Petty Cash', code: 'Petty Cash' },
        { title: 'PayNow', code: 'PayNow' },
        { title: 'Telegraph Transfer (Overseas)', code: 'Telegraph Transfer (Overseas)' },
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendor-payment/invoice/${this.$route.params.id}/preview`)
      .then(response => {
        this.invoice = response.data.data || {}
        this.purchaseRequest = response.data.purchaseRequest || {}
        this.expenseItems = response.data.expenseItems || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.from && to.query.from === 'my-vp') {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-my-vendor-payments-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'My Vendor Payments',
          to: '/purchasing/my-vendor-payments',
        },
        {
          text: 'Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-vendor-payments-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'Vendor Payments',
          to: '/purchasing/vendor-payments',
        },
        {
          text: 'Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    }
    next()
  },
  methods: {
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    closeRemarksModal() {
      this.$root.$emit('bv::hide::modal', 'modal-signatory-remarks', '')
    },
    closeFinanceRemarksModal() {
      this.$root.$emit('bv::hide::modal', 'modal-finance-remarks', '')
    },
    closePaymentStatusModal() {
      this.$root.$emit('bv::hide::modal', 'modal-finance-payment-status', '')
    },
    updateSignatoryRemarks(key) {
      this.signatoryIndex = key
      this.remarks = this.invoice.signatories[key].remarks
      this.$root.$emit('bv::show::modal', 'modal-signatory-remarks', '')
    },
    openPaymentRemarksModal() {
      this.financeRemarksStatus = this.invoice.financeRemarksStatus
      this.financeRemarks = this.invoice.financeRemarks
      this.$root.$emit('bv::show::modal', 'modal-finance-remarks', '')
    },
    openPaymentStatusModal() {
      this.financeRemarksStatus = this.invoice.financeRemarksStatus
      this.financeRemarks = this.invoice.financeRemarks
      this.paymentStatus = this.invoice.paymentStatus
      this.paymentMethod = this.invoice.paymentMethod
      this.$root.$emit('bv::show::modal', 'modal-finance-payment-status', '')
    },
    changeFinanceRemarks() {
      if (this.financeRemarksStatus === 'Under Processing') this.financeRemarks = 'Invoice is currently being processed'
      else if (this.financeRemarksStatus === 'Cheque Ready/ Online Payment Authorisation Completed') this.financeRemarks = 'Cheque is ready and has been collected/ sent by Singpost mail.  Online Payment Authorisation completed.'
      else if (this.financeRemarksStatus === 'Incomplete Documents') this.financeRemarks = 'The documents are incomplete. Documents are pending for further action/ signature.'
      else if (this.financeRemarksStatus === 'Insufficient Documents') this.financeRemarks = 'The documents are insufficient. There are omitted documents.'
      else if (this.financeRemarksStatus === 'Wrong Billing/ Company Name') this.financeRemarks = "Wrong Vendor has been billed or there is an error in the company's name."
      else if (this.financeRemarksStatus === 'Wrong Entity Raised by Requestor') this.financeRemarks = 'The wrong entity (NMG/ MPL/ PZPL) has been raised in the PRF/PO by the requestor.'
      else if (this.financeRemarksStatus === 'Irregular Signatory') this.financeRemarks = "Please refer to the Letter of Authority's Limit as there is an irregularity of signatures."
      else if (this.financeRemarksStatus === 'Wrong Amount/ GST') this.financeRemarks = 'The amount/ GST indicated is wrong.'
      else if (this.financeRemarksStatus === 'Tax Invoice Requested') this.financeRemarks = 'Tax invoice is requested by the IRAS regulations. To claim for GST, Tax invoices are required.'
    },
    getBadgeVariant(status) {
      if (status === 'Paid') return 'light-success'
      if (status === 'Processing') return 'light-info'
      if (status === 'Pending') return 'light-warning'
      if (status === 'Rejected') return 'light-danger'
      if (status === 'Cancelled') return 'light-danger'
      return 'light-primary'
    },
    saveFinanceRemarks() {
      this.$http
        .post('purchase/vendor-payment/finance/save-remarks', {
          financeRemarksStatus: this.financeRemarksStatus,
          financeRemarks: this.financeRemarks,
          id: this.invoice._id,
        })
        .then(response => {
          this.invoice.financeRemarksStatus = response.data.data.financeRemarksStatus
          this.invoice.financeRemarks = response.data.data.financeRemarks
          this.invoice.financeRemarksBy = response.data.data.financeRemarksBy
          this.closeFinanceRemarksModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    savePaymentStatus() {
      this.$http
        .post('purchase/vendor-payment/finance/save-payment-status', {
          financeRemarksStatus: this.financeRemarksStatus,
          financeRemarks: this.financeRemarks,
          paymentStatus: this.paymentStatus,
          paymentMethod: this.paymentMethod,
          id: this.invoice._id,
          url: window.location.origin,
        })
        .then(response => {
          this.invoice.financeRemarksStatus = response.data.data.financeRemarksStatus
          this.invoice.financeRemarks = response.data.data.financeRemarks
          this.invoice.paymentStatus = response.data.data.paymentStatus
          this.invoice.paymentMethod = response.data.data.paymentMethod
          this.invoice.financeRemarksBy = response.data.data.financeRemarksBy
          this.invoice.processedBy = response.data.data.processedBy
          this.invoice.processedDate = response.data.data.processedDate
          this.invoice.status = response.data.data.status
          this.closePaymentStatusModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveSignatoryRemarks() {
      this.$http
        .post('purchase/vendor-payment/signatory/save-remarks', {
          remarks: this.remarks,
          signatoryIndex: this.signatoryIndex,
          id: this.invoice._id,
          signatoryID: this.invoice.signatories[this.signatoryIndex]._id,
        })
        .then(response => {
          this.invoice.signatories[this.signatoryIndex].remarks = this.remarks
          this.closeRemarksModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getExpenseID(id = null) {
      if (id) {
        const found = this.expenseItems.find(o => o._id === id)
        if (found) {
          const title = `${found.expenseCategory.code || '-'} - ${found.expenseCategory.name}`
          return title
        }
      }
      return '-'
    },
    warningBeforeEdit(id) {
      this.$swal({
        title: 'Are You Sure?',
        // html: 'Editing the invoice now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this Invoice',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-request-update-invoice', params: { parent: this.purchaseRequest._id, id } })
          }
        })
    },
    warningBeforeCancel(id, invoiceID) {
      this.$swal({
        title: 'Cancel Invoice?',
        html: `Are you sure to proceed to cancel this<br>Invoice ${invoiceID}?`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Go Back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('id', id)
            formData.append('purchaseRequest', this.invoice.purchaseRequest)

            this.$http.post(`purchase/vendor-payment/invoice/${id}/cancel`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.purchaseRequest = response.data.pr
                this.invoice.status = 'cancelled'
                this.invoice.paymentStatus = 'Cancelled'
                this.$swal({
                  title: 'Invoice Cancelled!',
                  html: `${invoiceID} has been cancelled.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
    width: 90px;
}
.badge.light-font {
    font-weight: 400;
}
.modal-header.payment-status.front-modal {
    background: #21BB8B;
    padding: 24px 30px !important;
    button {
    background: rgba(255, 255, 255, 0.3) !important;
    margin-right: 20px !important;
    padding: 0;
    border-radius: 5px;
    min-width: 40px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    position: relative;
    }
    h3 {
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    }
    span.step-class {
    color: #fff !important;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 23px;
    font-size: 12px;
    display: block;
    }
    .feather {
    vertical-align: text-bottom;
    color: #fff;
    }
}
.btn-success-without-button {
    border-color: #21BB8B !important;
    background-color: #21BB8B !important;
    color: #fff;
    &:hover {
    color: #fff;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
